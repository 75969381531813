@font-face {
  font-family: "Platform";
  src: url("./assets/Platform-Bold.otf") format("opentype");
}

body {
  font-family: 'Poppins', sans-serif;
}

.container {
  text-align: center;
}

.container > p {
  font-size: 3rem;
  color: #078080;
}

.card-options {
  display: flex;
  margin: 2rem;
}

.option {
  padding: 2rem;
  width: 100%;
  background-color: #f8f5f2;
  border-radius: 3rem;
  margin: 1rem;
}

.option-img {
  max-width:50%;
  padding: 10%;
}

.option-text {
  font-size: 2rem;
  color: #078080;
  margin-block: 0;
}

header {
  position: relative;
  padding: 1rem;
  justify-content: center;
  background-image: url("./img/YellowWaves.png");
  background-repeat: repeat-x;
  background-size: contain;
  display: flex;
}

.bloom {
  object-fit: contain;
  position: absolute;
  top: -75px;
}

#left-bloom {
  left: -75px;
}

#right-bloom {
  right: -75px;
}

.cardPreview {
  margin-bottom: 1rem;
  border-radius: 2vh;
  box-shadow: 0 11px 20px #0003;
  height: calc((100vh - 2rem - 140px - 1rem - 28px - 3rem)/2 - 1rem);
}

.editor_preview {
  height: calc(100vh - 2rem - 140px);
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.lineText {
  border-block: 1px solid #232323;
  color: #232323;
  opacity: 15%;
  padding: 4px;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.newWorker {
  display: flex;
  padding-inline: 2rem;
}

.editor_container {
  width: 65%;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
}

.add-form {
  margin-bottom: 40px;
  text-align: center;
}

.form-control {
  margin: 20px 0;
}

.form-control label {
  display: block;
}

.form-control input {
  width: 100%;
  height: 20px;
  padding: 1rem 0 1rem 1rem;
  border-radius: 10px;
  font-size: 18px;
  border: 1px solid #F2F2F2;
  box-shadow: rgba(0,0,0, 5%) 3px 6px 12px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.gradient-option {
  width: 4rem;
  height: 4rem;
  border-radius: 15px;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-right: 1rem;
}

.gradient-option > img {
  object-fit: contain;
  max-height: 100%;
}

.colorSelector {
  display: flex;
  margin-block: 1rem;
}

.popup {
  display: flex;
  flex-direction: column;
  padding: 1rem 5rem;
}

.popup-content {
  background: #fff;
  width: 50%;
  padding: 5px;
  border: 1px solid #d7d7d7;
  border-radius: 1rem;
}

.popup-overlay {
  background: rgba(0,0,0,.5);
}

.btn {
  align-items: center;
  justify-content: space-between;
  background: #0A0A0A;
  color: #fffffe;
  border: 1px solid #F2F2F2;
  padding: 6px 1rem;
  border-radius: 25px;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  font-family: inherit;
  height: 40px;
  min-width: 10rem;
  box-shadow: rgba(0,0,0, 5%) 3px 6px 12px;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.whiteButton {
  background: #fff;
  color: #0A0A0A;
}